<script>
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'

export default {
    data() {
        return {
            api: RestApiType.ORDERS,
            content: null,
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                RestApiCollection.get(this.api)
                    .previewEmail(this.$route.params.id)
                    .then((res) => {
                        this.content = res
                    })
            },
        },
    },
}
</script>
<template>
    <!-- eslint-disable -->
    <div v-if="content" v-html="content"></div>
</template>
